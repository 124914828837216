import { connect } from 'react-redux';
import { ApplicationState } from '../../../appState';
import { GoogleMapProps, MapPurpose } from './GoogleMapProps';
import { MapMarkers } from '../MapMarkers';
import { GoogleMapRaw } from './GoogleMapRaw';

/** 
 *  A Google Map component optimised for the booking under construction in the booking widget.
 *  It will display the pickup location, dropoff location, and fare estimate route.
 */
export const BookingWidgetGoogleMap = connect(GetPropsFromStore)(GoogleMapRaw);

/** Connection from store state to local props. */
function GetPropsFromStore(state: ApplicationState): GoogleMapProps {
    return {
        pickupLocation: state.googleMap.pickupLocation,
        dropoffLocation: state.googleMap.dropoffLocation,
        mapCenter: state.googleMap.mapCenter || state.location.reliableLocation.value.geoPoint,
        zoom: state.googleMap.zoomLevel || 14,

        // no booking or car in this context (booking creation)
        carNumber: null,
        PickupMarker: MapMarkers.PickupLocation,
        PlannedRoute: state.googleMap.PlannedRoute,
        Purpose: MapPurpose.BookingWidget,
        Locations: null,
    };
}