import React from 'react';
import { GeoPoint } from '../../../modules/Location/Entities';
import { toGoogleLatLngV2 } from '../toGoogleLatLng';
import { GetValues } from '../../../Config/MyAppConfig';
import { GetMyDevice } from "../../../Config/MyAppConfig";
import { DeviceKind } from "../../../Config/Entities/DeviceKind";
import TaxiMarker from '../TaxiMarker';
import { MapMarkerImage, MapMarkers } from '../MapMarkers';
import { GoogleMapProps, MapPurpose } from './GoogleMapProps';
import appstore from '../../../appStore';
import { FeatureFlags } from '../../../Config/FeatureFlags';
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { GoogleMapsScript } from '../GoogleMapScriptLoader';

/** 
 *  Low level Google Maps component that renders the map and markers.
 *  Note: don't use this class directly in your code; use wrapped versions like BookingWidgetGoogleMap or TaxiTrackingGoogleMap.
 */
export const GoogleMapRaw: React.FC<GoogleMapProps> = (props) => {

    const googleScriptLoadResult = useJsApiLoader(GoogleMapsScript);

    // A reference to the underlying Google map, so we can do some non-react stuff
    const [googleMap, setGoogleMap] = React.useState<google.maps.Map | null>(null);

    // Non-React UI: zoom map out when we have a pickup and dropoff
    React.useLayoutEffect(UpdateMapBounds, [props.pickupLocation, props.dropoffLocation]);

    /** Store the map instance for non-React UI updates */
    function OnMapLoaded(map: google.maps.Map) {
        setGoogleMap(map);

        if (FeatureFlags.GoogleMapsBetaChannel) {

            const experienceId = appstore.getState().booking.GoogleOdrdTripId;

            if (experienceId) {
                google.maps.Settings.getInstance().experienceIds = [experienceId];
            }
        }
    }

    /** Executes the function on Map load with the map instance from GoogleMap Component */
    function UpdateMapBounds() {

        if (!googleMap) return;

        if (props.Purpose === MapPurpose.BookingWidget) {
            FitBoundsToLocations(googleMap);
        }
    }

    /** This will override the zoom and make sure the pickup and dropoff are in view. */
    function FitBoundsToLocations(map: google.maps.Map) {
        const pickup = props.pickupLocation;
        const dropoff = props.dropoffLocation;

        if (pickup && dropoff) {

            // there must be a better way to do this...
            const mapBounds: google.maps.LatLngBoundsLiteral = {
                north: min(pickup.latitude, dropoff.latitude),
                south: max(pickup.latitude, dropoff.latitude),
                west: min(pickup.longitude, dropoff.longitude),
                east: max(pickup.longitude, dropoff.longitude)
            }

            map.fitBounds(mapBounds);
        }
    }

    function min(left: number, right: number) {
        return left < right ? left : right;
    }

    function max(left: number, right: number) {
        return left < right ? right : left;
    }

    /** Renders a map marker if the corresponding location prop is defined. */
    function renderMarker(location: GeoPoint | undefined, markerImage: MapMarkerImage): JSX.Element | null {
        if (!location) return null;

        const icon: google.maps.Icon = {
            url: markerImage.Url,
            anchor: new google.maps.Point(markerImage.TargetPoint.x, markerImage.TargetPoint.y),
        };

        return <Marker position={toGoogleLatLngV2(location)} icon={icon} />;
    }

    /** Renders a taxi if the corresponding location prop is defined. */
    function renderTaxiMarker(): JSX.Element | null {
        if (!props.vehicleLocation) return null;

        return <TaxiMarker taxiLocation={props.vehicleLocation} carNumber={props.carNumber} />;
    }

    /** Renders the path / snail trail, if it exists in the props. */
    function renderDirections(): JSX.Element | null {

        if (!props.PlannedRoute) return null;

        return (
            <Polyline
                path={props.PlannedRoute}
                options={{ strokeColor: GetValues().BrandColour }}
            />
        );
    }

    /** Renders the map markers for different locations of the booking. We can get rid of the else block and obsolete props after multi-stop booking creation is implemented. */
    function RenderMapMarkers() {
        if (props.Locations && props.Locations.length > 0) {
            return props.Locations.map((location, index) => {
                if (index === 0) return renderMarker(location, props.PickupMarker);
                else if (index === props.Locations!.length - 1) return renderMarker(location, MapMarkers.DropoffLocation);
                else return renderMarker(location, MapMarkers.MultiStopMarker);
            })

        }
        else {
            return <>
                {renderMarker(props.pickupLocation, props.PickupMarker)}
                {renderMarker(props.dropoffLocation, MapMarkers.DropoffLocation)}
            </>;
        }
    }

    /** Avoids rendering anything until the Google Maps SDK has loaded. */
    function renderMainContent(): JSX.Element | null {

        if (!googleScriptLoadResult.isLoaded) {
            return null;
        }

        return (
            <GoogleMap
                onLoad={OnMapLoaded}
                center={toGoogleLatLngV2(props.mapCenter)}
                zoom={props.zoom}
                mapContainerClassName="google-maps-map"
                mapContainerStyle={{ height: `100%` }}
                options={{ disableDefaultUI: true, zoomControl: GetMyDevice() !== DeviceKind.Phone }} // Mobile device doesn't show zoom button
            >
                {RenderMapMarkers()}
                {renderTaxiMarker()}

                {renderDirections()}
            </GoogleMap>
        );
    }

    return (
        <div className="google-maps-container">
            {renderMainContent()}
        </div>
    );
}